import "./App.css";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import React, { useEffect, useState } from "react";
import "yet-another-react-lightbox/styles.css";
import { storage } from "./firebase";
import { Gallery } from "react-grid-gallery";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  deleteObject,
} from "firebase/storage";
import { v4 } from "uuid";
import { auth, signInWithGoogle, logOut } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function ImageUpload() {
  const [user] = useAuthState(auth);
  const [galleryImageUrls, setGalleryImageUrls] = useState([]);
  const [galleryImageUpload, setGalleryImageUpload] = useState([]);
  const [selectedGalleryImages, setSelectedGalleryImages] = useState([]);

  const [mainImageUrls, setMainImageUrls] = useState([]);
  const [mainImageUpload, setMainImageUpload] = useState([]);
  const [selectedMainImages, setSelectedMainImages] = useState([]);

  const deleteSelectedGalleryImages = async () => {
    try {
      // Delete each selected image from Firebase Storage
      await Promise.all(
        selectedGalleryImages.map(async (i) => {
          const imageRef = ref(storage, `/gallery/${i.id}`);
          await deleteObject(imageRef);
        })
      );

      //update the gallery to remove the deleted image
      const updatedGallery = galleryImageUrls.filter(
        (img) => !selectedGalleryImages.some((sel) => sel.id === img.id)
      );

      setGalleryImageUrls(updatedGallery);
      setSelectedGalleryImages([]);
      alert("Deleted images successfully!");
      window.location.reload();
    } catch (error) {
      alert("Permission Denied");
      console.error("Error deleting images:", error);
    }
  };

  const deleteSelectedMainImages = async () => {
    try {
      // Delete each selected image from Firebase Storage
      await Promise.all(
        selectedMainImages.map(async (i) => {
          const imageRef = ref(storage, `/main/${i.id}`);
          await deleteObject(imageRef);
        })
      );

      //update the gallery to remove the deleted image
      const updatedMain = mainImageUrls.filter(
        (img) => !selectedMainImages.some((sel) => sel.id === img.id)
      );

      setGalleryImageUrls(updatedMain);
      setSelectedGalleryImages([]);
      alert("Deleted images successfully!");
      window.location.reload();
    } catch (error) {
      alert("Permission Denied");
      console.error("Error deleting images:", error);
    }
  };

  const handleMainSelect = (index) => {
    const nextImages = mainImageUrls.map((image, i) =>
      i === index ? { ...image, isSelected: !image.isSelected } : image
    );
    setMainImageUrls(nextImages);

    setSelectedMainImages(nextImages.filter((i) => i.isSelected));
  };

  const handleGallerySelect = (index) => {
    const nextImages = galleryImageUrls.map((image, i) =>
      i === index ? { ...image, isSelected: !image.isSelected } : image
    );
    setGalleryImageUrls(nextImages);

    setSelectedGalleryImages(nextImages.filter((i) => i.isSelected));
  };

  const uploadGalleryImage = async (event) => {
    event.preventDefault();
    if (!galleryImageUpload || galleryImageUpload.length === 0) return;

    Array.from(galleryImageUpload).forEach(async (i) => {
      const name = `/gallery/${v4()}`;

      const imageRef = ref(storage, name);

      try {
        await uploadBytes(imageRef, i);
        const downloadURL = await getDownloadURL(imageRef);
        setGalleryImageUrls((prev) => [
          ...prev,
          { src: downloadURL, id: name, isSelected: false },
        ]);
        alert("Images uploaded successfully");
        window.location.reload();
      } catch (error) {
        alert("Permission Denied");
        console.error("Error uploading image:", error);
      }
    });
  };

  const uploadMainImage = async (event) => {
    event.preventDefault();
    if (!mainImageUpload || mainImageUpload.length === 0) return;

    Array.from(mainImageUpload).forEach(async (i) => {
      const name = `/main/${i.name + v4()}`;

      const imageRef = ref(storage, name);

      try {
        await uploadBytes(imageRef, i);
        const downloadURL = await getDownloadURL(imageRef);
        setMainImageUrls((prev) => [
          ...prev,
          { src: downloadURL, id: name, isSelected: false },
        ]);
        alert("Images uploaded successfully");
        window.location.reload();
      } catch (error) {
        alert("Permission Denied");
        console.error("Error uploading image:", error);
      }
    });
  };

  useEffect(() => {
    const galleryRef = ref(storage, `/gallery`);

    const fetchGalleryImages = async () => {
      try {
        const r = await listAll(galleryRef);
        const images = r.items.map(async (item) => {
          const url = await getDownloadURL(item);
          return {
            src: url,
            id: item.name,
            isSelected: false,
          };
        });

        const grid = await Promise.all(images);
        setGalleryImageUrls(grid);
      } catch (error) {
        console.log(error);
      }
    };
    fetchGalleryImages();
  }, []);

  useEffect(() => {
    const mainRef = ref(storage, `/main/`);

    const fetchMainImages = async () => {
      try {
        const r = await listAll(mainRef);
        const images = r.items.map(async (item) => {
          const url = await getDownloadURL(item);
          return {
            src: url,
            id: item.name,
            isSelected: false,
          };
        });

        const grid = await Promise.all(images);
        setMainImageUrls(grid);
      } catch (error) {
        console.log(error);
      }
    };
    fetchMainImages();
  }, []);

  return (
    <>
      {!user ? (
        <Container style={{ minHeight: "70vh" }}>
          <br />
          <Row>
            <button onClick={signInWithGoogle}>Sign in with Google</button>
          </Row>
        </Container>
      ) : (
        <>
          <Container style={{ minHeight: "70vh", padding: "1.5rem" }}>
            <Row>
              <Col sm={10}>
                <p>Welcome, {user.displayName}!</p>
              </Col>
              <Col>
                <Button
                  onClick={logOut}
                  className="rounded-0 custom-btn"
                  style={{
                    bottom: "10%",
                    left: "0%",
                  }}
                >
                  Log Out {String.fromCharCode(8594)}
                </Button>
              </Col>
            </Row>

            <h1>Gallery Images</h1>
            <Gallery images={galleryImageUrls} onSelect={handleGallerySelect} />
            <Row>
              <Button
                onClick={deleteSelectedGalleryImages}
                className="rounded-0 custom-btn"
                style={{}}
                disabled={selectedGalleryImages.length === 0}
              >
                Delete Images {String.fromCharCode(8594)}
              </Button>
            </Row>
            <div>
              <Form onSubmit={uploadGalleryImage}>
                <Form.Group controlId="formFileMultiple" className="mb-3">
                  <Form.Label>Upload Images</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/jpeg"
                    multiple
                    onChange={(event) =>
                      setGalleryImageUpload(event.target.files)
                    }
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <Button
                      type="submit"
                      className="rounded-0 custom-btn"
                      style={{
                        bottom: "10%",
                        left: "0%",
                      }}
                    >
                      Submit {String.fromCharCode(8594)}
                    </Button>
                  </Col>
                  <Col></Col>
                </Row>
              </Form>
            </div>
          </Container>

          <Container style={{ minHeight: "70vh", padding: "1.5rem" }}>
            <h1>Main Images</h1>
            <h4>**Please use 16:9 aspect ratio and JPG images only**</h4>
            <Gallery images={mainImageUrls} onSelect={handleMainSelect} />
            <Row>
              <Button
                onClick={deleteSelectedMainImages}
                className="rounded-0 custom-btn"
                style={{}}
                disabled={selectedMainImages.length === 0}
              >
                Delete Images {String.fromCharCode(8594)}
              </Button>
            </Row>
            <div>
              <Form onSubmit={uploadMainImage}>
                <Form.Group controlId="formFileMultiple" className="mb-3">
                  <Form.Label>Upload Images</Form.Label>
                  <Form.Control
                    type="file"
                    multiple
                    onChange={(event) => setMainImageUpload(event.target.files)}
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <Button
                      type="submit"
                      className="rounded-0 custom-btn"
                      style={{
                        bottom: "10%",
                        left: "0%",
                      }}
                    >
                      Submit {String.fromCharCode(8594)}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Container>
        </>
      )}
    </>
  );
}

export default ImageUpload;
