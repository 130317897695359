// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  fetchSignInMethodsForEmail,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCk_uz63hsoQnPY54QORbJ85Tp43GJDVIQ",
  authDomain: "lostcraftcarpentry-a88f4.firebaseapp.com",
  projectId: "lostcraftcarpentry-a88f4",
  storageBucket: "gs://lostcraftcarpentry-a88f4.firebasestorage.app",
  messagingSenderId: "502079381120",
  appId: "1:502079381120:web:6a25067880b7a7fc63d065",
  measurementId: "G-JFLTVKZ9HQ",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LfeoZEqAAAAAM1gCPV_yAeyYD6aEUkI3-XLJV70"),
});

export const storage = getStorage(app);
export const auth = getAuth(app);

const provider = new GoogleAuthProvider();

// Function to handle Google Sign-In
export const signInWithGoogle = async () => {
  //create is not allowed on the firebase auth. should throw exception if not already validated email
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
  } catch (error) {
    alert("Permission denied.");
    logOut();
    console.log(error);

    return;
  }
};

// Function to handle Sign-Out
export const logOut = () => signOut(auth);
