import "./App.css";
import {
  Container,
  Nav,
  Navbar,
  Row,
  Col,
  Button,
  Form,
  Spinner,
  ListGroup,
  Image,
  Card,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import React, { useEffect, useState, useRef } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Carousel from "react-bootstrap/Carousel";
import emailjs from "@emailjs/browser";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { storage } from "./firebase";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import ImageUpload from "./ImageUpload";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { app } from "./firebase";

const scrollToSection = (event, id) => {
  event.preventDefault();
  const element = document.querySelector(id);
  const navbarHeight = document.querySelector(".navbar").offsetHeight;

  if (element) {
    const offsetTop = element.offsetTop - navbarHeight;

    window.scrollTo({
      top: offsetTop,
      behavior: "smooth",
    });
  }
};

function App() {
  return (
    <>
      {/*header*/}
      <Navbar
        sticky="top"
        style={{
          boxShadow: " 0 2px 4px 0 rgba(0,0,0,.2)",
          backgroundColor: "white",
        }}
        variant="light"
        expand="md"
      >
        <Container>
          <Navbar.Brand href="/">
            <img
              src="/img/lccLogo.jpg"
              alt="Lost Craft Carpentry"
              height="100"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navBar" />
          <Navbar.Collapse id="navBar">
            <Nav className="ms-auto font d-flex justify-content-end">
              <Nav.Link
                href="#services"
                onClick={(e) => scrollToSection(e, "#services")}
              >
                Services
              </Nav.Link>

              <Nav.Link
                href="#about"
                onClick={(e) => scrollToSection(e, "#about")}
              >
                About
              </Nav.Link>

              <Nav.Link
                href="#gallery"
                onClick={(e) => scrollToSection(e, "#gallery")}
              >
                Gallery
              </Nav.Link>

              <Nav.Link
                href="#contact"
                onClick={(e) => scrollToSection(e, "#contact")}
              >
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Router>
        <Routes>
          <Route path="/imageUpload" element={<ImageUpload />} />
          <Route path="" element={<Main />} />
        </Routes>
      </Router>
      {/*main*/}

      <Footer />
    </>
  );
}

function Main() {
  return (
    <>
      <FullSizeCarousel />
      <Container id="home">
        <Row>
          <Col xs={12} className="flex-column justify-content-between">
            <h1 className="header">LOST CRAFT CARPENTRY</h1>

            <div className="mt-auto font">
              <p>
                Lost Craft Carpentry is a full-service carpentry and joinery
                company that offers a wide range of services. Our expertise lies
                in full-scale renovations and remodels, finish carpentry,
                historical restoration, custom woodworking, and millwork.
              </p>
              <p>
                As a small team, we are dedicated to bringing our clients’
                visions to life by creating curated spaces that are both
                functional and timeless in design. Our focus is on building
                high-end spaces using traditional building methods, designed to
                stand the test of time, and will continue to inspire for
                generations to come.
              </p>
              <p> Contact us today to get started on your next project!</p>
            </div>
            <div className="mt-auto">
              <i>&nbsp;</i>
            </div>
          </Col>
        </Row>
      </Container>
      <br />

      <WhatWeDo />

      {/* About */}
      <About />

      <ImageGrid />

      <GoogleReCaptchaProvider
        reCaptchaKey="6LfeoZEqAAAAAM1gCPV_yAeyYD6aEUkI3-XLJV70"
        container={{ id: "recaptchaCont" }}
      >
        <EmailForm />
      </GoogleReCaptchaProvider>
    </>
  );
}

function FullSizeCarousel() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const mainRef = ref(storage, `/main`);

    const fetchImages = async () => {
      setLoading(true);
      try {
        const r = await listAll(mainRef);
        const images = r.items.map(async (item) => {
          const url = await getDownloadURL(item);
          return {
            src: url,
            id: item.name,
          };
        });

        const grid = await Promise.all(images);
        setImages(grid);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    fetchImages();
  }, []);
  return (
    <>
      <div className="carousel-container">
        <Carousel fade controls={false} indicators={false}>
          {images.map((i) => (
            <Carousel.Item style={{ height: "100%" }} key={i.id}>
              <img className="d-block" src={i.src} alt="Lost Craft Carpentry" />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <br />
    </>
  );
}

function WhatWeDo() {
  const items = [
    "Renovations and remodels",
    "Architectural millwork, trim, and wall paneling",
    "Stairs and handrails",
    "Custom cabinetry and furniture",
    "Custom doors and entryways",
    "Historic window restoration, replication, and storm windows",
    "Heritage restoration woodworking",
  ];

  const ServiceList = ({ items }) => {
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("fade-in-active");
            }
          });
        },
        { threshold: 0.9 }
      );

      // Select all elements with the fade-in class and observe them
      document.querySelectorAll(".fade-in").forEach((element) => {
        observer.observe(element);
      });

      // Cleanup observer on component unmount
      return () => {
        observer.disconnect();
      };
    }, []);

    return (
      <ListGroup>
        {items.map((item, index) => (
          <ListGroup.Item
            key={index}
            className="rounded-0 fade-in font"
            style={{
              backgroundColor: index % 2 === 0 ? "#939b7b" : "#ebe3db",
              color: index % 2 === 0 ? "white" : "black",
              display: "flex",
              alignItems: "center",
              gap: "0.5em",
              marginBottom: "0.5em",
              transition: "transform 0.5s ease, opacity 0.3s ease",
            }}
          >
            <i
              className="bi bi-check2-square"
              style={{
                fontSize: "1rem",
                fontWeight: 700,
                width: "2em",
                height: "2em",
                backgroundColor: index % 2 === 0 ? "#ebe3db" : "#939b7b",
                color: index % 2 === 0 ? "black" : "white",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexShrink: 0,
              }}
            ></i>
            {item}
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  };
  return (
    <div width="100vw" className="font" style={{ backgroundColor: "#f8f5f1" }}>
      <Container id="services">
        <br />
        <div>
          <h1 className="header">WHAT WE DO</h1>
        </div>
        <Row>
          <Col xs={12}>
            <div className="mt-auto">
              <p>
                Lost Craft Carpentry offers a wide array of services. With our
                extensive skill set, we are more than happy to address any of
                your custom carpentry and joinery needs. Here is a list of some
                of the services we provide:
              </p>
            </div>
          </Col>
          <Col xs={12}>
            <div className="mt-auto">
              {" "}
              <div className="wrapper">
                <ServiceList items={items} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function About() {
  const [isTylerOverlayVisible, setTylerOverlayVisible] = useState(false);

  const toggleTylerOverlay = () => {
    setTylerOverlayVisible((prevState) => !prevState);
  };

  const [isKarleighOverlayVisible, setKarleighOverlayVisible] = useState(false);

  const toggleKarleighOverlay = () => {
    setKarleighOverlayVisible(!isKarleighOverlayVisible);
  };
  return (
    <>
      <div
        style={{
          width: "100vw",
          overflow: "hidden",
          backgroundColor: "#939b7b",
          color: "white",
        }}
        className="font"
      >
        <Container id="about">
          <br />
          <div>
            <h1 className="header">ABOUT</h1>
          </div>
          <Row style={{ marginBottom: "1.5rem" }}>
            <Col xs={12} md={5}>
              <Card className="rounded-0">
                <Card.Body>
                  <Card.Img
                    variant="top"
                    src={`/img/about/1.jpg`}
                    alt="Lost Craft Carpentry"
                    className="rounded-0"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={0} md={1}>
              {""}
            </Col>
            <Col
              xs={12}
              md={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div>
                <p>
                  Located in the Short Hills of Ontario’s Niagara Region, Lost
                  Craft Carpentry was founded by partners Karleigh Roberts and
                  Tyler Broydell in the summer of 2024. With over 15 years of
                  collective experience, Karleigh and Tyler have much to offer
                  as a small team with collaborative skills.
                </p>
                <br />
                <p>
                  The duo had met when Karleigh was introduced to Tyler by
                  mutual friends. Sharing similar interests in music, a love for
                  animals, matching ideologies, and a passion for their
                  respective crafts, the two quickly became inseparable and were
                  ready to take on anything as a team. The couple completed
                  their first project together after the same friends who
                  introduced them had asked them to create a photo booth for
                  their wedding (as seen above).
                </p>
                <br />
                <p>
                  Since then, Karleigh and Tyler have completed a wide range of
                  projects and are continuing to expand on the knowledge they’ve
                  gained throughout their careers.
                </p>
              </div>
            </Col>

            <Col md={3}>{""}</Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          width: "100vw",
          overflow: "hidden",
          backgroundColor: "#ebe3db",
        }}
        className="font"
      >
        <Container>
          <br />
          <Row>
            <Col xs={12} md={6}>
              <Card className="rounded-0 hover-card">
                <Card.Body className="card-body">
                  <div
                    className={`rounded-0 image-wrapper ${
                      isKarleighOverlayVisible ? "active" : ""
                    }`}
                  >
                    <Card.Img
                      className="rounded-0"
                      as={Image}
                      src={`/img/about/aboutKarleigh.JPEG`}
                      fluid={true}
                      alt="KARLEIGH ROBERTS"
                    />
                    <div className="hover-overlay font">
                      <p>
                        Karleigh is a Red Seal Carpenter with a lifelong passion
                        for carpentry. She had an incredible opportunity to
                        participate in the Women in Skilled Trades carpentry
                        program at Conestoga College. Following this she
                        completed all three levels of her apprenticeship with
                        honours. As a licensed carpenter, Karleigh brings a
                        wealth of experience in all aspects of renovations,
                        including framing, window and door installation,
                        drywall, and finish carpentry. Whatever the scope of
                        your project, she is fully equipped to manage every
                        detail with expertise and care.
                      </p>
                    </div>
                  </div>
                </Card.Body>
                <Card.Footer>
                  <Button
                    className="rounded-0 custom-btn d-block d-md-none"
                    onClick={toggleKarleighOverlay}
                  >
                    {isTylerOverlayVisible
                      ? "Close"
                      : `Read More ${String.fromCharCode(8594)}`}
                  </Button>
                  <Card.Title as="h5" className="d-none d-md-block">
                    KARLEIGH ROBERTS {/* Visible only on larger screens */}
                  </Card.Title>
                </Card.Footer>
              </Card>
            </Col>

            <Col xs={12} md={6}>
              <Card className="rounded-0 hover-card">
                <Card.Body className="card-body">
                  <div
                    className={`rounded-0 image-wrapper ${
                      isTylerOverlayVisible ? "active" : ""
                    }`}
                  >
                    <Card.Img
                      className="rounded-0"
                      as={Image}
                      src={`/img/about/aboutTyler.JPEG`}
                      fluid={true}
                      alt="TYLER BRODELL"
                    />
                    <div className="hover-overlay">
                      <p>
                        Tyler began his career in 2014, discovering his passion
                        for woodworking after attending college for furniture
                        and cabinetmaking. Throughout his career, Tyler has
                        expanded his knowledge in advanced woodworking while
                        working in custom cabinet shops, high-end stairs, and
                        working for a well-respected architectural millwork
                        company, restoring significant historical buildings and
                        national historic sites. Tyler finds inspiration in old
                        homes, and traditional design. Complex geometry and
                        joinery are what excite him most regarding his craft. If
                        it’s elaborate, curved, or challenging, he’s in his
                        element.
                      </p>
                    </div>
                  </div>
                </Card.Body>
                <Card.Footer>
                  <Button
                    className="rounded-0 custom-btn d-block d-md-none" // Visible only on small screens
                    onClick={toggleTylerOverlay}
                  >
                    {isTylerOverlayVisible
                      ? "Close"
                      : `Read More ${String.fromCharCode(8594)}`}
                  </Button>
                  <Card.Title as="h5" className="d-none d-md-block">
                    TYLER BROYDELL {/* Visible only on larger screens */}
                  </Card.Title>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <br />
        </Container>
      </div>
    </>
  );
}

function ImageGrid() {
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(-1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const galleryRef = ref(storage, `/gallery`);

    const fetchImages = async () => {
      setLoading(true);
      try {
        const r = await listAll(galleryRef);

        const images = r.items.map(async (item) => {
          const url = await getDownloadURL(item);

          //need to do this so we can get proper image size for the grid
          const img = new window.Image();

          return new Promise((resolve, reject) => {
            img.onload = () => {
              resolve({
                src: url,
                id: item.name,
                width: img.naturalWidth,
                height: img.naturalHeight,
                isSelected: false,
              });
            };

            img.onerror = (error) => reject(error);
            img.src = url;
          });
        });

        const grid = await Promise.all(images);
        setImages(grid);
      } catch (error) {
        console.error("Error fetching images: ", error);
      }
      setLoading(false);
    };

    fetchImages();
  }, []);

  const handleClick = (index) => {
    setIndex(index);
  };

  return (
    <Container id="gallery" className="gallery">
      <br />
      <h1 className="header">GALLERY</h1>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <Gallery
            images={images}
            enableImageSelection={false}
            onClick={handleClick}
            rowHeight={450}
          />
          <Lightbox
            slides={images.map((img) => ({
              src: img.src,
              caption: img.caption,
            }))}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
          />
        </>
      )}
    </Container>
  );
}

function EmailForm() {
  const [sendingEmail, setSendingEmail] = useState(false);

  const form = useRef();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const sendEmail = async (e) => {
    e.preventDefault();
    setSendingEmail(true);
    if (!executeRecaptcha) {
      alert("reCAPTCHA has not been loaded yet.");
      setSendingEmail(false);
      return;
    }

    const functions = getFunctions(app, "us-central1");
    const recapt = httpsCallable(functions, "verifyRecaptcha2");

    try {
      const token = await executeRecaptcha("email_form");

      if (!token) {
        alert("reCAPTCHA verification failed. Please try again.");
        setSendingEmail(false);
        return;
      }

      const verifyRecaptcha = await recapt({ token: token });

      const { success, serviceKey, templateKey, publicKey } =
        verifyRecaptcha.data;

      if (
        serviceKey === undefined ||
        templateKey === undefined ||
        publicKey === undefined ||
        !success
      ) {
        alert(
          "Failed to send email. Please try again in a moment, or send manually to lostcraftcaprentry@gmail.com."
        );
        setSendingEmail(false);
        return;
      }

      const init = await emailjs.init({ publicKey: publicKey });
      const result = await emailjs.sendForm(
        serviceKey,
        templateKey,
        form.current
      );
      alert("Successfully sent email.", result.text);
    } catch (error) {
      console.error("Error fetching keys or sending email!:", error);
      alert(
        "Failed to send email. Please try again in a moment, or send manually to lostcraftcaprentry@gmail.com."
      );
    } finally {
      setSendingEmail(false);
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        overflow: "hidden",
        backgroundColor: "#f8f5f1",
        paddingBottom: "1rem",
      }}
      className="font"
    >
      <Container id="contact">
        <br />
        <div>
          <h1 className="header">CONTACT</h1>
        </div>
        <Form ref={form} onSubmit={sendEmail}>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  className="rounded-0"
                  type="text"
                  name="name"
                  placeholder="Name"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  className="rounded-0"
                  type="email"
                  name="email_from"
                  placeholder="Email"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  className="rounded-0"
                  type="phone"
                  name="phone"
                  placeholder="Phone"
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  className="rounded-0"
                  as="textarea"
                  name="message"
                  rows={5}
                  placeholder="Message"
                  required
                />
              </Form.Group>
              <Button
                type="submit"
                className="rounded-0 custom-btn"
                style={{
                  bottom: "10%",
                  left: "0%",
                }}
                disabled={sendingEmail}
              >
                Submit {String.fromCharCode(8594)}
              </Button>
            </Col>
            <Col xs={12} md={6}>
              <p>
                If you have any questions or comments, please contact via email
                or phone, or send a message using the contact form.{" "}
              </p>
              <p>
                Email: lostcraftcarpentry@gmail.com <br />
                Phone: (705) 279-4216
              </p>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
}

function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="footer text-dark text-center font">
      <Container fluid>
        <br />
        <Row>
          <Col md={4} className="d-none d-sm-block">
            <img
              src="/img/lccLogo.jpg"
              alt="Lost Craft Carpentry"
              height="100"
              className="d-inline-block align-top"
            />
          </Col>

          <Col md={4}>
            <Row>
              <Col>
                <p className="bi bi-geo-alt-fill"> Fonthill, Ontario, Canada</p>

                <p className="bi bi-telephone-fill"> (705) 279-4216</p>
                <Nav.Link
                  className="font"
                  href="mailto:lostcraftcarpentry@gmail.com"
                >
                  <p className="bi bi-chat-fill">
                    {" "}
                    lostcraftcarpentry@gmail.com
                  </p>
                </Nav.Link>
              </Col>
            </Row>
          </Col>

          <Col md={4}>
            <div className="social text-dark fs-3">
              <a href="https://www.facebook.com/people/Lost-Craft-Carpentry/61564218716212/">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="https://instagram.com/lostcraftcarpentry">
                <i className="bi bi-instagram"></i>
              </a>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <p>&copy; {year} Lost Craft Carpentry. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default App;
